import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from "../state/store";

interface UserInfo {
    email: string;
    shortUrl: string;
    expireDaysFromNow: number;
}
function OrderPage() {
    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
    const email = useSelector((state: RootState) => state.login.email);
    const token = useSelector((state: RootState) =>  state.login.token);
    const BASE_URL = 'http://101proxy.com/api/short_url/';

    useEffect(() => {
        fetch(`http://localhost:8080/api/user/info?email=${email}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => response.json())
            .then(data => setUserInfo(data))
            .catch(error => console.error('Error:', error));
    }, []);

    if (!userInfo) {
        return <div>Loading...</div>;
    }

    return (
        <div className="bg-gray-800 text-white min-h-screen">
            <div className="bg-blue-900 p-4 text-center text-2xl font-bold">101加速器</div>
            <div className="p-4">
                <div className="bg-gray-700 p-4 mb-4">
                    <div className="text-lg mb-2">我的订单</div>
                    <div className="flex justify-between items-center mb-2">
                        <div>订阅套餐：9元包月套餐</div>
                        <div className="text-blue-400">续费&gt;&gt;&gt;</div>
                    </div>
                    <div className="mb-2">过期时间：{userInfo.expireDaysFromNow}天后过期</div>
                    <div className="flex justify-between items-center">

                        <div>订阅链接：{BASE_URL + userInfo.shortUrl}</div>
                        <button
                            className="text-blue-400"
                            onClick={() => navigator.clipboard.writeText(BASE_URL + userInfo.shortUrl)}
                        >
                            复制
                        </button>
                    </div>
                </div>
                <div className="flex justify-between items-center bg-gray-700 p-4 mb-4">
                    <button className="bg-blue-900 py-2 px-4 flex-1 mr-2">重置链接</button>
                    <button className="bg-gray-600 py-2 px-4 flex-1 ml-2">导入客户端</button>
                </div>
                <div className="bg-gray-700 p-4 text-center text-lg mb-4">
                    <div className="mb-2">操作步骤</div>
                    <div className="flex justify-around items-center">
                        <div><i className="fas fa-user-circle text-2xl"></i>
                            <div>购买订阅</div>
                        </div>
                        <div><i className="fas fa-arrow-right text-xl"></i></div>
                        <div><i className="fas fa-arrow-down text-2xl"></i>
                            <div>导入客户端</div>
                        </div>
                        <div><i className="fas fa-arrow-right text-xl"></i></div>
                        <div><i className="fas fa-globe text-2xl"></i>
                            <div>启动代理</div>
                        </div>
                    </div>
                </div>
                <div className="bg-gray-700 p-4">
                    <div className="text-lg mb-2">推荐客户端软件</div>
                    <div className="mb-2">苹果手机 | Shadowrocket</div>
                    <div className="mb-2">安卓手机 | V2ray</div>
                    <div className="mb-2">安卓电脑 | ClashX</div>
                    <div>PC电脑 | V2ray</div>
                </div>
            </div>
        </div>
    )
}

export default OrderPage