import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import LoginPage from "./loginPage/LoginPage";
import HomePage from "./homePage/HomePage";
import OrderPage from "./orderPage/OrderPage";
import store from './state/store';
import {Provider} from 'react-redux';

const router =createBrowserRouter([{path: '', element: <HomePage/>}, {path: '/login', element: <LoginPage/>}, {path: '/order', element: <OrderPage/>}])
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <RouterProvider router={router}/>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
