import {Link} from "react-router-dom";
import React, {useState} from "react";
import './HomePage.css'; // Import the CSS file
import {useDispatch, useSelector} from 'react-redux';
import {fillEmail, fillPassword, selectDuration, selectPlan} from '../state/home/HomeSlices';
import {RootState} from "../state/store";
import {Radio, TextField} from "@mui/material";

function HomePage() {

    const apiUrl = process.env.REACT_APP_API_URL;
    console.log("------PAYMENT_API_URL",apiUrl);

    const dispatch = useDispatch();
    const {selectedPlan, selectedDuration, email, password,confirmationPassword} = useSelector((state: RootState) => ({
        selectedPlan: state.home.selectedPlan,
        selectedDuration: state.home.selectedDuration,
        email: state.home.email,
        password: state.home.password,
        confirmationPassword: state.home.comfirmationPassword
    }));
    const onClickSelectPlan = (plan: string) => {
        dispatch(selectPlan(plan));
    };

    const onClickSelectDuration = (duration: string) => {
        dispatch(selectDuration(duration));
    };

    // Step 1: Create a state variable for the error message
    const [emailError, setEmailError] = useState("");
    function validateEmail(email: string) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    function startPayment() {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                mail: email,
                accountPassword: password,
                payAmount: '1', // replace with the actual amount
                subscriptionTypeId: 1, // replace with the actual subscriptionTypeId
                paymentType: 1 // replace with the actual paymentType
            })
        };
        console.log("process.env.API_URL",process.env.API_URL)
        fetch(`${apiUrl}/api/payments/start`, requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log(data);
                if (data.code === 200) {
                    alert('Please finish your payment.'); // Display a pop-up message
                }
            });
    }



    return (<div className="home bg-gray-900 text-white p-4 max-w-md mx-auto h-screen">
        <header className="text-3xl font-bold text-center mb-6">
            <div className="mb-4">101加速器</div>
            <div className="flex justify-between items-center">
                <div>选择套餐</div>
                <Link to="/login" className="text-blue-500 text-sm">查询订单&gt;&gt;&gt;</Link>
            </div>
        </header>
        <div className="flex justify-between mb-6">
            <div onClick={() => onClickSelectPlan("9")}
                 className={`bg-gray-800 p-4 rounded-lg text-sm ${selectedPlan === "9" ? 'selected' : ''}`}>
                <div className="text-xl font-bold mb-4">9元/月</div>
                <div className="flex items-center mb-2">
                    <i className="fas fa-check-circle text-green-500 mr-2"></i>
                    <span>每月350G流量</span>
                </div>
                <div className="flex items-center mb-2">
                    <i className="fas fa-check-circle text-green-500 mr-2"></i>
                    <span>流速4K秒开</span>
                </div>
                <div className="flex items-center mb-2">
                    <i className="fas fa-check-circle text-green-500 mr-2"></i>
                    <span>解锁ChatGPT</span>
                </div>
                <div className="flex items-center mb-2">
                    <i className="fas fa-check-circle text-green-500 mr-2"></i>
                    <span>超过20个节点可用</span>
                </div>
                <div className="flex items-center">
                    <i className="fas fa-check-circle text-green-500 mr-2"></i>
                    <span>允许2台设备同时使用</span>
                </div>
            </div>
            <div onClick={() => onClickSelectPlan("19")}
                 className={`bg-gray-800 p-4 rounded-lg text-sm ${selectedPlan === "19" ? 'selected' : ''}`}>
                <div className="text-xl font-bold mb-4">19元/月</div>
                <div className="flex items-center mb-2">
                    <i className="fas fa-check-circle text-green-500 mr-2"></i>
                    <span>每月1000G流量</span>
                </div>
                <div className="flex items-center mb-2">
                    <i className="fas fa-check-circle text-green-500 mr-2"></i>
                    <span>流速4K秒开</span>
                </div>
                <div className="flex items-center mb-2">
                    <i className="fas fa-check-circle text-green-500 mr-2"></i>
                    <span>解锁ChatGPT</span>
                </div>
                <div className="flex items-center mb-2">
                    <i className="fas fa-check-circle text-green-500 mr-2"></i>
                    <span>超过20个节点可用</span>
                </div>
                <div className="flex items-center">
                    <i className="fas fa-check-circle text-green-500 mr-2"></i>
                    <span>允许5台设备同时使用</span>
                </div>
            </div>
        </div>
        <div className="mb-6">
            <div className="flex justify-between mb-4">
                {['1个月', '3个月', '6个月', '12个月'].map((duration, index) => (
                    <div key={index} onClick={() => onClickSelectDuration(duration)}
                         className={`bg-gray-800 p-2 rounded-lg text-center ${selectedDuration === duration ? 'selected' : ''}`}>
                        {duration}
                    </div>))}
            </div>
            <div className="flex justify-between items-center bg-gray-800 p-2 rounded-lg mb-4">
                {/*<input type="text" placeholder="接收邮箱（订阅查询使用一致）"*/}
                {/*       className="bg-gray-800 text-white outline-none w-full"*/}
                {/*       value={email}*/}
                {/*       onChange={(e) => dispatch(fillEmail(e.target.value))}/>*/}
                <TextField
                    type="text"
                    placeholder="接收邮箱（订阅查询使用一致）"
                    className="bg-gray-800 text-white outline-none w-full"
                    value={email}
                    onChange={(e) => {
                        const emailValue = e.target.value;
                        dispatch(fillEmail(emailValue));

                        // Step 2: Validate the email and set the error message
                        if (!validateEmail(emailValue)) {
                            setEmailError("Invalid email address");
                        } else {
                            setEmailError("");
                        }
                    }}
                    // Step 3: Pass the error message as the helperText prop
                    helperText={emailError}
                    // Step 4: Set the error prop based on whether there is an error message
                    error={!!emailError}
                    inputProps={{style: {color: 'white'}}}
                />
            </div>
            <div className="bg-gray-800 p-2 rounded-lg mb-4">
                <TextField
                    type="password"
                    placeholder="订阅查询密码"
                    className="bg-gray-800 text-white outline-none w-full"
                    value={password}
                    onChange={(e) => dispatch(fillPassword(e.target.value))}
                    inputProps={{style: {color: 'white'}}}
                />
            </div>
        </div>
        <div onClick={() => console.log('支付宝支付')}
             className={`flex items-center`}>
            <div className="bg-gray-800 p-2 rounded-full mr-2">
                <Radio
                    checked={true}
                    // onChange={() => setSelectedPaymentMethod('微信支付')}
                    value="微信支付"
                    name="payment-method"
                    inputProps={{'aria-label': '微信支付'}}
                />
            </div>
            <span>微信支付</span>
        </div>
        <div className="fixed-bottom bg-white text-gray-900 mt-5 p-4">
            <div className="flex justify-between items-center mb-2">
                <div className="text-2xl">应付:</div>
                <div className="text-3xl text-orange-500">54.00 元</div>
            </div>
            <button
                className={`bg-blue-600 text-white p-2 rounded-lg w-full ${!selectedPlan || !selectedDuration || !email || !password ? 'bg-gray-500' : ''}`}
                disabled={!selectedPlan || !selectedDuration || !email || !password}
                onClick={startPayment}>
                确认付款
            </button>
        </div>
    </div>)
}

export default HomePage